<!--footer-->
<div class="footer">
    <div class="wrapper">
   <div class="copyrights">Copyright &copy; {{currentYear}} BASF Corporation &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Privacy policy &nbsp;&nbsp; ⧁ &nbsp;<a href="https://www.basf.com/us/en/legal/data-protection.html" target="_blank" style="text-decoration: underline; color: #fff;">US</a> &nbsp;&nbsp;&nbsp; ⧁ &nbsp; <a href="https://www.basf.com/ca/en/legal/data-protection" target="_blank" style="text-decoration:underline; color:#fff;">Canada</a></div>
   <div>
    <ul>
      <li><a href="https://www.basf.com/us/en/legal/e-business.html" target="_blank">⧁ Business</a></li>
      <li><a href="https://www.basf.com/us/en/legal/disclaimer.html" target="_blank">⧁ Disclaimer</a></li>
      <li><a href="https://www.basf.com/us/en.html#" target="_blank">⧁ Cookie Preference Center</a></li>
      <li><a href="https://www.basf.com/us/en/legal/credits.html" target="_blank">⧁ Credits</a></li>
      <li><a href="https://www.basf.com/us/en/legal/contact.html" target="_blank">⧁ Contact</a></li>
      <li><a href="https://www.basf.com/global/en/legal/data-protection-at-basf.html" target="_blank">⧁ Data Protection</a></li>
    </ul>
   </div>
  </div>
  </div>
